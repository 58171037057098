import { useRef } from 'react'
import styled from 'styled-components'
import useTranslation from 'next-translate/useTranslation'

import device from 'utils/device'
import Download from 'components/Download'
import Footer from 'components/Footer'
import useReveal from 'hooks/useReveal'
import Reveal from 'components/Reveal'
import Button from 'components/Button'

export default function Start({cta, buttons, type, hm}) {
    const pathRef = useRef()
    const { t } = useTranslation('common')

    const isVisible = useReveal(pathRef, { threshold: 0.5 });

    return (
        <Full>
            <Container ref={pathRef}>
                <SmallCircle animate={isVisible} />
                <Circle>
                    <Fill animate={isVisible}/>
                    <Texts>
                        <Reveal delay="2">
                            <h2 className="h1">{t('footer_banner_name')}</h2>
                        </Reveal>
                    </Texts>
                    <Reveal delay="3">
                        {hm ? <Button href="/" as="a">Learn More</Button> : cta ? cta : buttons ? buttons() : <Download />}
                    </Reveal>
                </Circle>
            </Container>
            <Footer type={type} />
        </Full>
    )
}

const Full = styled.div`
    overflow: hidden;
`

const Container = styled.div`
    position: relative;
    width: var(--full);
    margin: 0 auto;
    ${device.mobile} {
        width: 100%;
    }
`

const Circle = styled.div`
    width: 6.8rem;
    height: 6.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position:relative;
    * {
        position: relative;
    }
    ${device.mobile} {
        width: 3.75rem;
        height: 3.75rem;
    }
`

const Fill = styled.div`
    background: var(--color-accent);
    border-radius: 50%;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform: scale(0);
    
    transition: transform 1s eas-in-out;
    @keyframes circle2 {
        100% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    ${props=>props.animate ? 'animation: circle2 1s ease-out forwards;' : ''}
`

const Texts = styled.div`
    width: 4.85rem;
    text-align: center;
    h2 {
        margin: 0 0 0.24rem;
    }
    ${device.mobile} {
        width: var(--wrap-mobile);
    }
`


const SmallCircle = styled.div`
    width: 1.2rem;
    height: 1.2rem;
    background: var(--color-orange);
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -3.45rem;
    ${device.mobile} {
        width: 0.64rem;
        height: 0.64rem;
        left: 0.01rem;
        margin: 0;
    }
    transform: scale(0);
    transition: transform 1s eas-in-out;
    @keyframes circle2 {
        100% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    ${props=>props.animate ? 'animation: circle2 1s ease-out forwards;' : ''}
`
