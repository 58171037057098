const params = {
    openWaitlist: false,
    hiddenPages: ['/bio-age-test', '/app-features', '/faq'],
    googlePlayUrl: 'https://www.google.com/',
    appStoreUrl: 'https://www.apple.com/',
    facebookUrl: 'https://www.facebook.com/vitalhabitsapp',
    instagramUrl: 'https://www.instagram.com/vitalhabitsapp',
    // linkedinUrl: 'https://www.linkedin.com/company/vital-habits-app',
    // twitterUrl: 'https://twitter.com/vitalhabitsapp'
}

export default params
