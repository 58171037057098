import styled from 'styled-components'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import Svg from 'components/Svg'
import device from 'utils/device'
import params from 'utils/params'

export default function Footer({type, naked}) {
    const { t } = useTranslation('common')

    return (
        <Container naked={naked} type={type}>
            <Left>
                © {new Date().getFullYear()} {t('footer_copy')} <i />
                <Link href="/about/terms-and-conditions">{t('footer_terms')}</Link> {t('footer_and')}{' '}
                <Link href="/about/privacy-policy">{t('footer_privacy')}</Link>
                {/* © {new Date().getFullYear()} UAB Vital Habits. Vilniaus g. 9-1, LT-01102 Vilnius
                <br/>
                All rights reserved. <i />
                <Link href="/about/terms-and-conditions">{t('footer_terms')}</Link> {t('footer_and')}{' '}
                <Link href="/about/privacy-policy">{t('footer_privacy')}</Link> */}
            </Left>
            <Center>
                {/* <Link href="/faq">{t('footer_faq')}</Link> */}
                <Link href="/articles">{t('footer_blog')}</Link>
                <Link href="/help">{t('footer_help')}</Link>
                <Link href="/contact">{t('footer_contact_us')}</Link>
            </Center>
            <Right>
                {params.facebookUrl && (
                    <a aria-label="Vital Habits Facebook page" href={params.facebookUrl} rel="noreferrer" target="_blank">
                        <Svg name="facebook" />
                    </a>
                )}
                {params.instagramUrl && (
                    <a aria-label="Vital Habits Instagram page" href={params.instagramUrl} rel="noreferrer" target="_blank">
                        <Svg name="instagram" />
                    </a>
                )}
                {params.linkedinUrl && (
                    <a aria-label="Vital Habits Linkedin page" href={params.linkedinUrl} rel="noreferrer" target="_blank">
                        <Svg name="linkedin" />
                    </a>
                )}
                {params.twitterUrl && (
                    <a aria-label="Vital Habits Twitter page" href={params.twitterUrl} rel="noreferrer" target="_blank">
                        <Svg name="twitter" />
                    </a>
                )}
            </Right>
        </Container>
    )
}

const Container = styled.div`
    position: relative;
    display: flex;
    width: var(--wrap);
    margin: 0 auto;
    padding: 0.32rem 0;
    border-top: ${props=>props.naked ? 0 : 1}px solid currentColor;
    justify-content: space-between;
    color: ${props => props.type === 'light' ? 'rgba(255,255,255,1)' : 'var(--color-dark)'};
    ${device.mobile} {
        width: var(--wrap-mobile);
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
`

const Left = styled.div`
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    ${device.mobile} {
        order: 3;
        i {
            display: block;
        }
    }
`

const Center = styled.div`
    a {
        margin: 0 0.12rem;
        &:hover {
            text-decoration: underline;
        }
    }
    ${device.mobile} {
        order: 1;
        margin-bottom: 0.24rem;
    }
`

const Right = styled.div`
    display: flex;
    a {
        margin-left: 0.16rem;
        display: block;
        width: 0.24rem;
        height: 0.24rem;
        color: currentColor;
        svg {
            width: 0.24rem;
            height: 0.24rem;
        }
    }
    ${device.mobile} {
        order: 2;
        margin-bottom: 0.24rem;
    }
`
